import { graphql, useStaticQuery } from 'gatsby'

const useScheduleQuery = () => {
  const { contentfulSchedulePage: page } = useStaticQuery(graphql`
    {
      contentfulSchedulePage {
        id
        title
        benefitsList
        videoLink
      }
    }
  `)

  return page
}
export default useScheduleQuery
