import * as React from 'react'
import {
  BenefitList,
  BenefitListItem,
  GlobalStyle,
  Logo,
  Main,
  Nav,
  SBSRight,
  SBSTitle,
  ScheduleTitle,
  SideBySide,
  Video,
  VideoContainer,
} from '../styles'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import LogoSrc from '../images/logo.png'
import Apply from '../components/Apply'
import useScheduleQuery from '../scheduleGraphql'
import ReactPlayer from 'react-player/youtube'

const ScheduleInterview = () => {
  const page = useScheduleQuery()
  console.log(page)

  React.useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const { videoLink, title, benefitsList } = page
  return (
    <React.Fragment>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Schedule Interview</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Play:wght@700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Nav>
        <Logo src={LogoSrc} alt="logo" />
        <Link to="/">
          <Apply setApplyOpen={() => {}} isOpen={false} />
        </Link>
      </Nav>
      <Main>
        <SideBySide>
          <VideoContainer>
            <Video>
              <ReactPlayer
                className="react-player"
                height="100%"
                width="100%"
                url={videoLink}
                controls={true}
                config={{
                  youtube: {
                    playerVars: { showinfo: 1 },
                  },
                }}
              />
            </Video>
          </VideoContainer>
          <SBSRight>
            <SBSTitle>{title}</SBSTitle>
            <BenefitList>
              {benefitsList.map((b) => (
                <BenefitListItem>> {b}</BenefitListItem>
              ))}
            </BenefitList>
          </SBSRight>
        </SideBySide>
        <ScheduleTitle>Schedule Your Interview</ScheduleTitle>
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/odesseyenergy/15min?hide_gdpr_banner=1"
          style={{ height: 900 }}
        />
      </Main>
    </React.Fragment>
  )
}

export default ScheduleInterview
